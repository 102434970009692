import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    Typography,
    Box,
    Card,
    CardContent,
} from "@mui/material";
import { getOrderStatusAPI, postOrderStatusAPI } from "../../utils/ordersControlar.js";
import { cx } from "../../utils/cx.js";
import { useGlobalOrder } from "../../utils/GlobalStateContext.jsx";
import Timer from "./Timer.jsx";
import {toast} from "react-toastify";

function DiningOut() {
    const { ordersStatusList, setOrderStatusList, urlType } = useGlobalOrder();

    const handleOrderStatusChange = async (id, type) => {
        try {
            if(urlType === 2){
                return toast.error("無法操作");
            }
            await postOrderStatusAPI(id, type);
        } catch (error) {
            console.error("Failed to change order status:", error);
        }
    };

    const handleClick = (item) => {
        if(item.status !== 4){
            handleOrderStatusChange(item.id,1);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await getOrderStatusAPI(urlType);
            setOrderStatusList(data);
        };

        fetchData();

        const intervalId = setInterval(async () => {
            const data = await getOrderStatusAPI(urlType);
            setOrderStatusList(data);
        }, 300000);

        return () => clearInterval(intervalId);
    }, [setOrderStatusList,urlType]);

    // 偵測手指滑動的方向
    const handleSwipe = (item) => {
        let touchStartX = 0;
        let touchEndX = 0;

        const onTouchStart = (e) => {
            touchStartX = e.touches[0].clientX;
        };

        const onTouchEnd = (e) => {
            touchEndX = e.changedTouches[0].clientX;
            if (touchEndX < touchStartX) {
                // 左滑，執行 handleOrderStatusChange(item.id, 1)
                handleOrderStatusChange(item.id, 1);
            } else if (touchEndX > touchStartX) {
                // 右滑，執行 handleOrderStatusChange(item.id, 2)
                handleOrderStatusChange(item.id, 2);
            }
        };

        return { onTouchStart, onTouchEnd };
    };

    return ordersStatusList?.length ? (
        <Grid
            container
            direction="column"
            sx={{ backgroundColor: "#f0f0f0", height: "100vh" }}
        >
            <Grid
                item
                container
                direction="row"
                sx={{
                    height: "calc(100% - 80px)",
                    flexGrow: 1,
                    display: "flex",
                    flexWrap: "nowrap",
                    border: "none",
                    borderTop: "1px solid #797777",
                }}
            >
                <Grid
                    item
                    sx={{
                        padding: 2,
                        border: "none",
                        borderRight: "1px solid #797777",
                        height: "100%",
                        overflowY: "auto",
                    }}
                    xs={12}
                    className="hide-scrollbar grid gap-[16px] grid-cols-3"
                >
                    {ordersStatusList.map((order, index) => (
                        <Grid item key={index}>
                            <Card
                                sx={{
                                    width: "100%",
                                    height: "fit-content",
                                    backgroundColor: order.bgcolor ,
                                    display: "flex",
                                    flexDirection: "column",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                    borderRadius: 4,
                                }}
                            >
                                <CardContent
                                    sx={{
                                        paddingBottom: 0,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    onDoubleClick={()=>{
                                        handleOrderStatusChange(order.order_id,3);
                                    }}>
                                    <Typography
                                        sx={{
                                            fontSize: "28px",
                                            fontWeight: "700",
                                            color: order.color ? order.color : "#000",
                                            flexGrow: 1,
                                            textAlign: "left",
                                        }}
                                    >
                                        {order.order_no}
                                    </Typography>
                                    {urlType === 2 ? (
                                        <Box sx={{ marginLeft: 2 }}>
                                            <Typography
                                                align="center"
                                                sx={{
                                                    color: "#A43B3B",
                                                    fontSize: "24px",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {order.finished_at}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box sx={{ marginLeft: 2 }}>
                                            <Typography
                                                align="center"
                                                sx={{
                                                    color: "#A43B3B",
                                                    fontSize: "24px",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                <Timer dateTime={order.datetime} />
                                            </Typography>
                                        </Box>
                                    )}
                                </CardContent>
                                {order.note ? (
                                    <CardContent
                                        sx={{
                                            padding: 0,
                                            display: "flex",
                                            alignItems: "center",
                                        }}>
                                        <Typography
                                            sx={{
                                                fontSize: "24px",
                                                color: "#000",
                                                flexGrow: 1,
                                                textAlign: "center",
                                            }}
                                        >
                                            <b>備註：{order.note}</b>
                                        </Typography>
                                    </CardContent>
                                ) : ''}
                                <CardContent sx={{ paddingTop: 0, gap: "8px", display: "grid" }}>
                                    {order.detail.map((item, index) => {
                                        const { onTouchStart, onTouchEnd } = handleSwipe(item);
                                        return (
                                            <div
                                                key={`${order.order_no}${index}`}
                                                className={cx(
                                                    `rounded-2xl flex p-[16px] justify-between`,
                                                    item.status === 0
                                                        ? "bg-dark_origin"
                                                        : item.status === 1
                                                            ? "bg-card_dark_red"
                                                            : item.status === 2
                                                                ? "bg-dark_blue"
                                                                : item.status === 3
                                                                    ? "bg-dark_green"
                                                                    : "bg-[#838383]"
                                                )}
                                                onTouchStart={onTouchStart}
                                                onTouchEnd={onTouchEnd}
                                                onClick={()=>handleClick(item)}
                                            >
                        <span className="grid w-full">
                          <Typography
                              variant="body2"
                              align="left"
                              color="#FFF"
                              sx={{ fontSize: "26px" }}
                          >
                            <b>{`${item.no}. ${item.name}`}</b>
                          </Typography>
                          <span className="flex flex-wrap text-[#FFF] text-[20px]">
                              {item.note.map((note, index) => {
                                  if(note.type === 2){
                                      return <span className="inline-block bg-red-800 text-white px-2 py-1 rounded-full">
                                          {note.text}
                                      </span>
                                  }else if(note.type === 3){
                                      return <span className="inline-block bg-amber-600 text-white px-2 py-1 rounded-full">
                                          {note.text}
                                      </span>
                                  }else{
                                      return <Typography align="left" color="#FFF" sx={{ fontSize: "20px" }}>
                                          {note.text} /
                                      </Typography>
                                  }
                              })}
                              {item.note2.map((note, index) => {
                                  if(note.type === 2){
                                      return <span className="inline-block bg-red-800 text-white px-2 py-1 rounded-full">
                                          {note.text}
                                      </span>
                                  }else if(note.type === 3){
                                      return <span className="inline-block bg-amber-600 text-white px-2 py-1 rounded-full">
                                          {note.text}
                                      </span>
                                  }else{
                                      return <Typography align="left" color="#FFF" sx={{ fontSize: "20px" }}>
                                          {note.text} /
                                      </Typography>
                                  }
                              })}
                          </span>
                        </span>
                                                {item.status <= 3 ? (<Typography
                                                    color="#FFF"
                                                    sx={{
                                                        fontSize: "14px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "20px",
                                                    }}
                                                >
                                                    {`${
                                                        item.status === 0
                                                            ? "未做"
                                                            : item.status === 1
                                                                ? "製作"
                                                                : item.status === 2
                                                                    ? "已完"
                                                                    : item.status === 3
                                                                        ? "已出"
                                                                        : ""
                                                    }`}
                                                </Typography>) : ''}

                                            </div>
                                        );
                                    })}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <Typography
            variant="h3"
            sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
                alignItems: "center",
            }}
        >
            目前沒有進單
        </Typography>
    );
}

export default DiningOut;
