import React, { createContext, useState, useEffect, useContext, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { getOrders, getOrderStatusAPI } from "./ordersControlar";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [systemData, setSystemData] = useState(null);
    const [globalOrder, setGlobalOrder] = useState(null);
    const [todayOrders, setTodayOrders] = useState(null);
    const [foodData, setFoodData] = useState(null);
    const [ordersStatusList, setOrderStatusList] = useState(null);
    const [ws, setWs] = useState(null);
    const [urlType, setUrlType] = useState(1);
    const [barcode, setBarcode] = useState('');
    const [barcodeInput, setBarcodeInput] = useState('');

    const audioRef = useRef(null);           // 音頻引用
    const beepIntervalRef = useRef(null);    // 重複播放計時器引用
    const audioContextRef = useRef(null);    // AudioContext 引用

    const playBeep = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.volume = 1;
            audioRef.current.currentTime = 0; // 归零重新播放
            audioRef.current.play().catch((error) => {
                console.log("音頻播放失敗:", error);
            });
        }
    }, []);

    // 解鎖音頻播放：使用 click 與 touchstart 事件，同時 Resume AudioContext
    useEffect(() => {
        // 初始化 AudioContext（如果支持）
        if (!audioContextRef.current && window.AudioContext) {
            audioContextRef.current = new AudioContext();
        }
        if (audioRef.current === null) {
            audioRef.current = new Audio('/beep/beep.mp3');
            audioRef.current.volume = 1;
            audioRef.current.load(); // 預載音頻文件
        }

        const unlockAudio = () => {
            // 若 AudioContext 處於 suspended 狀態，則恢復它
            if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
                audioContextRef.current.resume().then(() => {
                    console.log("AudioContext 已恢復");
                });
            }
            // 靜音播放一次以解鎖自動播放權限
            if (audioRef.current) {
                audioRef.current.muted = true;
                audioRef.current.play().catch(() => {}).finally(() => {
                    audioRef.current.muted = false;
                });
            }
            document.removeEventListener('click', unlockAudio);
            document.removeEventListener('touchstart', unlockAudio);
        };

        document.addEventListener('click', unlockAudio);
        document.addEventListener('touchstart', unlockAudio);

        return () => {
            document.removeEventListener('click', unlockAudio);
            document.removeEventListener('touchstart', unlockAudio);
        };
    }, []);

    // 以下省略部分 WebSocket 初始化與其他邏輯，可照原有方式保留
    const initializeWebSocket = useCallback((position_id) => {
        const socket = new WebSocket(
            (process.env.NODE_ENV === "production" ? 'wss://www.ciaowin35.com/ws' : 'ws://debug.ciaowin35.com:9601') + '?id=' + position_id
        );

        socket.onopen = () => {
            console.log('WebSocket連接成功');
            socket.send(JSON.stringify({ message: 'Hello from client!' }));
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log('收到的消息:', message, position_id);

            if (message.type === 'updateOrder' && currentTab === 0) {
                getOrders({ now: 1 }).then((response) => {
                    setTodayOrders(response);
                });
            }

            if (message.type === 'updateProduct') {
                setSystemData((prevSystemData) => ({
                    ...prevSystemData,
                    meal: message.data.meal,
                    menu: message.data.menu,
                    food: message.data.food,
                }));
            }

            if (message.type === 'updateMake') {
                getOrderStatusAPI().then((response) => {
                    setUrlType(1);
                    setOrderStatusList(response);
                });
            }

            if (message.type === 'alert') {
                if (!beepIntervalRef.current) {
                    beepIntervalRef.current = setInterval(playBeep, 1500);
                }
                playBeep();
                const option = message.big ? {
                    autoClose: false,
                    closeOnClick: true,
                    style: {
                        background: "rgba(216,0,0,0.8)",
                        color: "white",
                        width: "90vw",
                        height: "90vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "24px",
                        zIndex: 9999,
                    },
                    position: "top-center",
                    onClose: () => {
                        clearInterval(beepIntervalRef.current);
                        beepIntervalRef.current = null;
                    },
                } : {
                    autoClose: false,
                    onClose: () => {
                        clearInterval(beepIntervalRef.current);
                        beepIntervalRef.current = null;
                    },
                };

                message.status === 1
                    ? toast.info(message.msg, option)
                    : toast.error(message.msg, option);
            }

            if (message.type === 'notify') {
                playBeep();
                message.status === 1
                    ? toast.info(message.msg)
                    : toast.error(message.msg);
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket錯誤:', error);
        };

        socket.onclose = () => {
            console.log('WebSocket連接關閉，重試連接');
            setTimeout(() => initializeWebSocket(position_id), 500);
        };

        setWs(socket);
    }, [playBeep, currentTab]);

    useEffect(() => {
        if (systemData?.position_id && !ws) {
            initializeWebSocket(systemData.position_id);
        }
        return () => {
            if (ws) ws.close();
        };
    }, [initializeWebSocket, systemData]);

    // 心跳檢測
    useEffect(() => {
        const interval = setInterval(() => {
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ type: 'heartbeat' }));
            }
        }, 30000);
        return () => clearInterval(interval);
    }, [ws]);

    const sendMessage = (message) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(message));
        } else {
            console.error('WebSocket連接未開啟，無法發送消息');
        }
    };
    const BarcodeScanner = () => {
        const inputRef = useRef(null);

        useEffect(() => {
            // 畫面載入後自動聚焦輸入框
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, []);

        const handleChange = (e) => {
            setBarcodeInput(e.target.value);
        };

        const handleKeyDown = (e) => {
            // 假設掃描器在掃描完成後會傳送 Enter 鍵
            if (e.key === 'Enter') {
                console.log('掃描到的條碼:', barcodeInput);
                // 可在此進行條碼驗證或呼叫 API 等處理
                // 處理完畢後清空狀態，等待下一次掃描
                setBarcode(barcodeInput);
                setBarcodeInput("");
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }
        };

        return (
            <input
                ref={inputRef}
                type="text"
                value={barcodeInput}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none"
                style={{ position: 'absolute', left: '-1000px' }}
            />
        );
    };

    return (
        <GlobalStateContext.Provider value={{
            globalOrder,
            setGlobalOrder,
            systemData,
            setSystemData,
            foodData,
            setFoodData,
            ordersStatusList,
            setOrderStatusList,
            playBeep,
            toast,
            sendMessage,
            currentTab,
            setCurrentTab,
            todayOrders,
            setTodayOrders,
            urlType,
            setUrlType,
            BarcodeScanner,
            barcode,
            setBarcode
        }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

export const useGlobalOrder = () => useContext(GlobalStateContext);