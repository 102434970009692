import { useEffect, useRef, useState } from "react";
import { Box, TextField, Button, Grid, Snackbar } from "@mui/material";
import {useGlobalOrder} from "../../../utils/GlobalStateContext";
import { BrowserBarcodeReader, NotFoundException } from "@zxing/library"; // 引入 NotFoundException


const LinePay = (props) => {
  const { BarcodeScanner, barcode, setBarCode} = useGlobalOrder() ;

  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const { unpaid_amount, clickCheckOut , back } = props;
  const [complete, setComplete] = useState(false);


  const handlePayment = (data) => {
    // 在這裡處理付款邏輯
    clickCheckOut({
      line_code: data,
    });
    //支付成功
    if(unpaid_amount <= 0){
      setComplete(true);
    }
  };

  useEffect(() => {
    handlePayment(barcode)
  }, [barcode]);

  const codeReaderRef = useRef(null); // 用來持有codeReader的引用
  useEffect(() => {
    const codeReader = new BrowserBarcodeReader();
    codeReaderRef.current = codeReader; // 將codeReader儲存在引用中

    const startScanning = async () => {
      try {
        //console.log('Starting scanner...');
        await codeReader.decodeFromVideoDevice(
            null,
            videoRef.current,
            (result, err) => {
              if (result) {
                //console.log('Scan result:', result.text);
                setBarCode(result.text);
              }
              if (err) {
                //console.log('Scan error:', err);
                if (!(err instanceof NotFoundException)) {
                  setError(`掃描錯誤: ${err.message || "未知錯誤"}`);
                }
              }
            }
        );
        //console.log('Scanner started.');
      } catch (error) {
        //console.error('啟動掃描器時出錯:', error);
        setError(`啟動掃描器錯誤: ${error.message || "未知錯誤"}`);
      }
    };

    startScanning();

    // 清理函數
    return () => {
      console.log("Cleaning up scanner...");
      if (codeReaderRef.current) {
        codeReaderRef.current.reset(); // 停止掃描器
      }
    };
  }, []);

  return !complete ? (
    <Box sx={styles.container}>
      <p style={styles.title}>LinePay付款</p>

      {BarcodeScanner()}

      <Box sx={styles.scannerContainer}>
        <video ref={videoRef} width="100%" height="100%" />
      </Box>

      <TextField
        value={barcode}
        variant="outlined"
        fullWidth
        InputProps={{ sx: styles.input }}
        sx={styles.textField}
      />

      <Grid container sx={{ marginTop: 2 }} spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={styles.button}
            onClick={() => handlePayment(barcode)}
          >
            <p style={styles.buttonText}>扣款</p>
          </Button>
          <Button
              variant="contained"
              fullWidth
              size="large"
              sx={styles.returnButton}
              onClick={() => {back();}}
          >
            <p style={styles.returnButtonText}>返回</p>
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  ) : (
    <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
      結帳完成
    </h1>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "16px",
    height: "100%",
    width: "100%",
  },
  title: {
    marginBottom: "16px",
    minWidth: "80px",
    color: "#797777",
    letterSpacing: "0.1em",
  },
  scannerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    marginBottom: "16px",
    overflow: "hidden",
  },
  scanner: {
    width: "280px",
    height: "210px",
  },
  input: {
    height: "44px",
    borderRadius: "9999px",
    fontSize: "20px",
    color: "#797777",
    fontWeight: 700,
    backgroundColor: "#FFF",
    "&::placeholder": { color: "#C0C0C0" },
    "&::-webkit-calendar-picker-indicator": { display: "none" },
  },
  textField: {
    borderRadius: "9999px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root": {
      "& fieldset": { border: "none" },
      "&:hover fieldset": { border: "none" },
      "&.Mui-focused fieldset": { border: "none" },
    },
  },
  button: {
    backgroundColor: "#FFF",
    height: "50px",
    borderRadius: 2,
    padding: "8px 8px",
    marginBottom: "10px",
  },
  returnButton: {
    backgroundColor: "#b1b1b1",
    height: "50px",
    borderRadius: 2,
    padding: "8px 8px",
  },
  buttonText: {
    color: "#8B0000",
    fontWeight: 1000,
  },
  returnButtonText: {
    color: "#424141",
    fontWeight: 1000,
  },
};

export default LinePay;
