import {Button, Grid, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useGlobalOrder} from "../../utils/GlobalStateContext";
import {cx} from "../../utils/cx";
import Option from "../Dialogs/Option.tsx";
import Delete from "../Dialogs/Delete.tsx";
import {toast} from "react-toastify";

//type=1 代表是訂單詳情 =2 代表是訂單歷史
function OrderDetail({orderDetail, clickItem, type, handleOpenOrderDialog,submit,handleDeleteItem}) {

    const navigate = useNavigate();
    const {systemData, setGlobalOrder} = useGlobalOrder();
    const scrollContainerRef = useRef(null); // 使用 useRef 獲取滾動容器的參考

    const [dialogOpen, setDialogOpen] = useState({
        option: false,
        delete: false,
    });

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight; // 滾動到底部
        }
    }, [orderDetail]);

    if (!orderDetail) {
        return '';
    }

    // 打開彈窗
    const handleOpenDialog = (dialogType) => {
        setDialogOpen((prev) => ({...prev, [dialogType]: true}));
    };

    // 關閉彈窗
    const handleCloseDialog = (dialogType) => {
        setDialogOpen((prev) => ({...prev, [dialogType]: false}));
    };

    return (
        <>

            {/* 右侧订单详情区域 */}
            <Grid
                item
                sx={{
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
                className="hide-scrollbar"
                xs={3}
            >
                {orderDetail.mode ? (
                    <Typography
                        variant="h6"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            bgcolor: "#8f2222",
                            height: "30px",
                            marginX: "-16px",
                            paddingX: "30px",
                        }}
                    >
                        <p className="sidebar_btn_text text-[#FFF]">{orderDetail.mode === 1 ? "提單模式" : (orderDetail.mode === 2 ? "改單模式" : "重訂模式")}</p>
                    </Typography>
                ) : ''}

                {orderDetail?.note1 ? (
                    <Typography
                        variant="h6"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            bgcolor: "#053d4e",
                            height: "30px",
                            marginX: "-16px",
                            paddingX: "30px",
                        }}
                    >
                        <p className="sidebar_btn_text text-[#FFF]">{orderDetail?.note1}</p>
                    </Typography>
                ) : ''}

                {orderDetail?.note2 ? (
                    <Typography
                        variant="h6"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            bgcolor: "#4c4c4c",
                            height: "30px",
                            marginX: "-16px",
                            paddingX: "30px",
                        }}
                    >
                        <p className="sidebar_btn_text text-[#FFF]">{orderDetail?.note2}</p>
                    </Typography>
                ) : ''}

                <Grid
                    container
                    sx={{
                        marginBottom: 2,
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        paddingX: "8px",
                    }}
                >
                    <Grid item xs={6} sx={{paddingLeft: "8px"}}>
                        <p className="sidebar_btn_text_b text-sold_out_gray text-left">
                            品名
                        </p>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <p className="sidebar_btn_text_b text-sold_out_gray">數量</p>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <p className="sidebar_btn_text_b text-sold_out_gray">小計</p>
                    </Grid>
                </Grid>
                {orderDetail?.buycart && (
                    <div
                        ref={scrollContainerRef}
                        style={{
                            height: "calc(100% - 295px)",
                            overflow: "auto",
                            scrollBehavior: "smooth",
                        }}
                        className="hide-scrollbar"
                    >
                        {orderDetail?.buycart?.length > 0 &&
                            orderDetail?.buycart?.map((item, index) => {
                                const mealData = systemData.meal.find(
                                    (data) => data.id === item.meal_id
                                );
                                return (
                                    <Grid
                                        container
                                        key={index}
                                        sx={{
                                            marginBottom: 1,
                                            borderRadius: "8px",
                                            padding: "8px",
                                            backgroundColor: "#E2E2E2",
                                        }}
                                        onClick={() => {
                                            if(item.locked_at){
                                                toast.error("已鎖定商品，如需刪除請雙點");
                                            }else{
                                                clickItem(mealData, item);
                                            }
                                        }}
                                        onDoubleClick={(e) => {  // 雙擊事件
                                            if(item.locked_at){
                                                handleDeleteItem(item, index) ;
                                            }
                                        }}
                                    >
                                        <>
                                            <Grid item xs={1}>
                                                <p  className={cx(
                                                    `sidebar_btn_text text-left`,
                                                    item.print === 1 && "text-dark_red"
                                                )}>
                                                    {`${index + 1}.`}
                                                </p>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <p
                                                   className={cx(
                                                       `sidebar_btn_text text-left`,
                                                       item.print === 1 && "text-dark_red"
                                                   )}>
                                                    {mealData?.name || "已下架商品"}
                                                </p>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <p
                                                    className={cx(
                                                        `sidebar_btn_text `,
                                                        item.print === 1 && "text-dark_red"
                                                    )}
                                                >
                                                    {item.qty}
                                                </p>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <p className={cx(
                                                    `sidebar_btn_text`,
                                                    item.print === 1 && "text-dark_red"
                                                )}>
                                                    {item?.point ? (item.point + '點') : item?.money}
                                                </p>
                                            </Grid>
                                        </>
                                        {item.detail?.length > 0 && (
                                            <Grid
                                                sx={{
                                                    marginY: "4px",
                                                    gap: "4px",
                                                }}
                                                display={"flex"}
                                                flexWrap={"wrap"}
                                            >
                                                {item.detail.map((tag) => {
                                                    // Find the corresponding food item from systemData.food based on categoryId
                                                    const foodData = systemData.food.find(
                                                        (food) => food.pd_id === tag.food_id
                                                    );
                                                    return foodData ? (
                                                        <span
                                                            key={tag.oi_id}
                                                            className="sidebar_btn_text text-[16px] w-fit flex text-nowrap bg-card_bg_gray rounded-full p-[4px_8px] text-[#FFF]"
                                                        >
                                {foodData?.price > 0
                                    ? `+${foodData.name} $${foodData.price}`
                                    : `${foodData.name}`}
                              </span>
                                                    ) : (
                                                        ""
                                                    );
                                                })}
                                            </Grid>
                                        )}

                                        {item.note && (
                                            <Grid item xs={12}>
                                                <p className="sidebar_btn_text text-ligth_red text-left">{`備註:${item.note}`}</p>
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            })}
                    </div>
                )}

                <Grid
                    direction="row"
                    container
                    sx={{marginTop: 0.2}}
                    spacing={2}
                >
                    {type === 1 ? (
                        <Grid item xs={4} sx={{ bgcolor: "#838383",marginTop:'16px'}}>
                            <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                sx={{
                                    backgroundColor: "#FFF",
                                    borderRadius: 7,
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                    padding: "8px 16px",
                                }}
                                disabled={!orderDetail || [7, 8].includes(orderDetail?.status)}
                                onClick={() => handleOpenDialog('option')}
                            >
                                <p className="sidebar_btn_text text-[#000]">
                                    操作
                                </p>
                            </Button>
                        </Grid>
                    ) : ''}

                    <Grid item xs={type === 1?8:12}>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                bgcolor: "#838383",
                                marginX: "-16px",
                                alignItems: "center",
                                paddingX: "16px",
                            }}
                        >
                            <p className="text_default_style text-[#FFF]">{`總額:`}</p>
                            <p className="text_default_style text-[#FFF]">${orderDetail.order_money}</p>
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                bgcolor: "#838383",
                                marginX: "-16px",
                                alignItems: "center",
                                paddingX: "16px",
                            }}
                        >
                            <p className="text_default_style text-[#FFF]">{`折扣:`}</p>
                            <p className="text_default_style text-[#FFF]">${orderDetail.discount_fee}</p>
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                bgcolor: "#838383",
                                marginX: "-16px",
                                alignItems: "center",
                                paddingX: "16px",
                            }}
                        >
                            <p className="text_default_style text-[#FFF]">{`小計:`}</p>
                            <p className="text_default_style_b text-[#FFF]">${orderDetail.order_total}</p>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid sx={{marginTop: "5px"}}>
                    <Grid
                        direction="row"
                        container
                        sx={{fontWeight: "bold"}}
                        spacing={2}
                    >
                        {type === 1 ? (
                            <>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        size="large"
                                        disabled={!orderDetail.order_id || orderDetail.buycart.length === 0 || orderDetail.mode === 2}
                                        sx={{
                                            backgroundColor: "#FFF",
                                            height: "80px",
                                            borderRadius: 7,
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                            padding: "8px 16px",
                                        }}
                                        onClick={() => submit()}
                                    >
                                        <p className="text_default_style text-[#000] font-[700]">
                                            送單
                                        </p>
                                    </Button>
                                </Grid>


                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        size="large"
                                        disabled={!orderDetail.order_id || orderDetail.buycart.length === 0}
                                        sx={{
                                            backgroundColor: "#FFF",
                                            height: "80px",
                                            borderRadius: 7,
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                            padding: "8px 16px",
                                        }}
                                        onClick={() => handleOpenOrderDialog("payment")}
                                    >
                                        <p className="text_default_style text-dark_red font-[700]">
                                            結帳
                                        </p>
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="large"
                                    disabled={!orderDetail}
                                    sx={{
                                        backgroundColor: "#FFF",
                                        height: "80px",
                                        borderRadius: 7,
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                        padding: "8px 16px",
                                    }}
                                    onClick={() => {
                                        setGlobalOrder({
                                            ...orderDetail, // 展開 orderDetail 物件
                                            mode: (([0, 1, 2].includes(orderDetail?.status)
                                                ? 1
                                                : [7, 8].includes(orderDetail?.status)
                                                    ? 3
                                                    : 2))
                                        });
                                        navigate("/order");
                                    }}
                                >
                                    <p className="text_default_style text-dark_red font-[700]">
                                        {[0, 1, 2].includes(orderDetail?.status)
                                            ? "提單"
                                            : [7, 8].includes(orderDetail?.status)
                                                ? "重訂"
                                                : "改單"}
                                    </p>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <Option
                open={dialogOpen.option}
                handleClose={() => handleCloseDialog('option')}
                orderId={orderDetail?.order_id}
                handleOpenDialog={handleOpenDialog}
            />

            <Delete
                setOrdersData={()=>{}}
                open={dialogOpen.delete}
                handleClose={() => {handleCloseDialog('delete')} }
                orderId={orderDetail?.order_id}
            />
        </>
    )
}

export default OrderDetail;