import {Button, Box, Typography} from "@mui/material";
import {useNavigate, useLocation} from "react-router-dom";
import {cx} from "../../utils/cx";
import {printOrder} from "../../utils/ordersControlar.js";
import {useGlobalOrder} from "../../utils/GlobalStateContext";

const Sidebar = ({setTodayOrders, systemData, setSystemLogin, menuItems, setCurrentTab, setUrlType, urlType}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {playBeep} = useGlobalOrder(); // 全局 toast 提示

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
                p="1px"
            >
                {menuItems.map((item, index) => (
                    <Button
                        className="max-w-[100px] max-h-[80px]"
                        key={item.label}
                        fullWidth
                        size="large"
                        // disabled={!memberLogin}
                        onClick={() => {
                            if (window.location.href.includes("dining-out") && item.id === 4) {
                                setUrlType(urlType === 1 ? 2 : 1);
                            } else {
                                navigate(item.path);
                            }
                            setCurrentTab(item.id);
                            //playBeep();
                        }}
                        sx={{
                            mt: 2,
                            display: "grid",
                            borderRadius: 3,
                            backgroundColor:
                                location.pathname === item.path ? "#FFF" : "#616161", // 根據當前路由設定背景顏色
                            gap: 1,
                        }}
                    >
                        {item.image ? (
                            <img
                                src={item.image}
                                alt={`left_sidebar_btm_${index}`}
                                className="max-w-[35px] max-h-[35px] object-contain mx-auto"
                            />
                        ) : ''}

                        <p
                            className={cx(
                                `sidebar_btn_text ${
                                    location.pathname === item.path
                                        ? "text-card_dark_red font-[700]"
                                        : "text-light_gray font-[400]"
                                }`
                            )}
                        >
                            {(urlType === 2 && item.id === 4) ? "已出單" : item.label}
                        </p>
                    </Button>
                ))}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
                p="1px"
            >
                {systemData.position_id === 3 ? (
                    <Button
                        className="max-w-[100px] max-h-[80px]"
                        key="呼叫櫃檯"
                        fullWidth
                        size="large"
                        onClick={() => {
                            printOrder({orderId: 0, type: 6})
                        }}
                        sx={{
                            mb: 2,
                            backgroundColor: "#616161",
                            borderRadius: 3,
                        }}
                    >
                        <p className="text-light_gray sidebar_btn_text">呼叫櫃檯</p>
                    </Button>
                ) : ""}

                {systemData.position && (
                    <>
                        <Typography
                            sx={{
                                width: "134px",
                                color: "#FFF",
                                fontSize: "20px",
                                lineHeight: "24.2px",
                                textAlign: "center",
                                fontWeight: "400",
                                letterSpacing: 4,
                                marginBottom: 2,
                            }}
                            align="center"
                        >
                            {systemData.position}
                        </Typography>
                    </>
                )}
            </Box>
        </>
    );
};


export default Sidebar;
